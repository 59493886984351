import { IMAGES } from "../constent/theme"
import { Link } from "react-router-dom"
import emailjs from "@emailjs/browser"
import toast, { Toaster } from "react-hot-toast"
import { useRef, useState } from "react"

const Footer2 = () => {
  const heartRef = useRef(null)
  const [input, setInput] = useState("")
  const form = useRef(null)
  const sendEmail = e => {
    e.preventDefault()
    setInput("")
    if (form.current) {
      emailjs
        .sendForm(
          "emailId",
          "template_0byuv32",
          form.current,
          "qUDIPykc776NYHv4m"
        )
        .then(
          () => {
            toast.success("Successfully send!")
          },
          error => {
            toast.error(error.text)
          }
        )
    }
  }
  return (
    <>
      <Toaster position="bottom-right" reverseOrder={true} />
      <footer className="site-footer style-2" id="footer">
        <div className="footer-bg-wrapper" id="app-banner">
          <div className="footer-top">
            <div className="container">
            
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 wow fadeInUp">
                  <div className="widget widget_getintuch">
                    <h5 className="footer-title">Contact</h5>
                    <ul>
                      <li>
                        <i className="flaticon-placeholder"></i>
                        <a className="text-white" href="https://www.google.com/maps/place/187+Harvard+St,+Brookline,+MA+02446/@42.3393137,-71.1234925,17z/data=!3m1!4b1!4m6!3m5!1s0x89e37995ffbeca59:0x8fa99e137912a24!8m2!3d42.3393098!4d-71.1209176!16s%2Fg%2F11c2hxxmk_?entry=ttu" target="">
                        187 Harvard Street Brookline MA 02446, USA
                        </a>
                      </li>
                      <li>
                        <i className="flaticon-telephone"></i>
                        <a href="tel:+16172772999" className="text-white">
                        (617) 277-2999 
                        <br/>
                        (617) 277-6699
                        </a>
                      </li>
                      <li>
                      <p className="text-white">
        Mon - Sat: 11:30-21:15<br />
        Sun: 12:00-21:15
        <br/>
        Wed-Closed{" "}
      </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6">
                  <div className="widget widget_services">
                    <h5 className="footer-title">Our Links</h5>
                    <ul>
                      <li>
                        <Link to="/">
                          <span>Home</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/about">
                          <span>About Us</span>
                        </Link>
                      </li>
                      
                      {/* <li>
                        <Link to="/online-order">
                          <span>Online Order</span>
                        </Link>
                      </li> */}
                      <li>
                        <Link to="/menu">
                          <span>Menu</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget_services">
                    <h5 className="footer-title">OUR SERVICES</h5>
                    <ul>
                  
                      <li>
                        <Link to="/">
                          <span>Top Chefs</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact">
                          <span>Services</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <span>Pickup In Store</span>
                        </Link>
                      </li>
                     
                    </ul>
                  </div>
                </div>
                  
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-bottom">
            <div className="row">
                <span className="copyright-text">
                  Crafted With{" "}
                  <span
                    className="heart"
                    ref={heartRef}
                    onClick={() => {
                      heartRef.current?.classList.toggle("heart-blast")
                    }}
                  ></span>{" "}
                  by{" "}
                  <Link to="/">
                    Hutao Online
                  </Link>
                </span>
              </div>
              
            </div>
        </div>
      </footer>
    </>
  )
}

export default Footer2
