import { Link, useNavigate } from "react-router-dom";
import { IMAGES } from "../constent/theme";
import video from '../assets/video/video3.mp4'
import poster2 from '../assets/images/vidposter2.png'

const Home2OurBlog = () => {
  const navigate = useNavigate();
  return (
    <section className="content-inner overflow-hidden">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-12">
            <div className="section-head">
              <h2 className="title wow flipInX">Our Restaurant</h2>
            </div>
            <div className="testimonial-2">
            <div className="testimonial-detail">
              <div className="testimonial-text wow fadeInUp">
                <p>
                Get the best sushi in Brookline at Rainbow Sushi. Whether you are looking for raw sushi or cooked sushi. We also offer delivery services for sushi in Boston. In addition to sushi catering services for events or large gatherings in surrounding cities around Brookline. Each one of our customers enjoys eating are freshly made sushi to order. Not only that Rainbow Sushi in Brookline also offers orders for pickup if you don't have time to dine in. Simply pick up the phone and give us a call or come in to enjoy the best sushi in Brookline, Massachusetts.
                </p>
              </div>
              <div className="testimonial-info wow fadeInUp"> 
                <h5 className="testimonial-name">We Offer Delivery Within 5 Miles</h5>
                <span className="testimonial-position">Rainbow Sushi</span>
                </div>
               
                <div
    onClick={() => navigate("/online-order")}
    className="btn btn-primary btn-md shadow-primary m-r30 btn-hover-1"
>
    <span>Order Online</span>
</div>
            </div>
          </div>
            
          </div>
          <div className="col-xl-6 col-lg-12 m-b30 wow fadeInUp">
            <div
              className="dz-card style-3 dz-card-large"
              style={{ backgroundImage: `url(${IMAGES.blog_large_pic1})` }}
            >
              <video autoPlay loop muted playsInline poster={poster2}>
                <source src={video} type="video/mp4"  />
              </video>
              <div className="dz-info">
                <h3 className="dz-title">
                  <Link to="/" className="text-white">
                    Exploring the World of Sushi
                  </Link>
                </h3>
                <div className="dz-meta">
                  {/* <ul>
                    <li className="dz-date">14 Feb 2023</li>
                    <li className="dz-user">
                      <Link to="#">
                        <i className="fa-solid fa-user"></i>
                        By <span>KK Sharma</span>
                      </Link>
                    </li>
                    <li className="dz-comment">
                      <Link to="#">
                        <i className="fa-solid fa-message"></i>
                        15 Comments
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home2OurBlog;
